export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [6,[2]],
		"/accountant-helper": [20],
		"/(app)/analytics": [7,[2]],
		"/attachment-viewer": [21],
		"/(app)/invoices": [8,[2]],
		"/(app)/organization": [9,[2,3]],
		"/(app)/organization/billing": [10,[2,3]],
		"/(app)/organization/clients": [11,[2,3]],
		"/(app)/organization/clients/callback": [12],
		"/(app)/organization/teams": [13,[2,3]],
		"/(app)/organization/users": [14,[2,3]],
		"/(app)/settings/(settings)": [15,[2,4]],
		"/(app)/settings/account": [16,[2,4]],
		"/(app)/settings/previous-system-notifications": [17,[2,4]],
		"/(app)/settings/progressive-automation": [18,[2,4]],
		"/(app)/settings/rated-predictions": [19,[2,4]],
		"/user/login": [22,[5]],
		"/user/reset-password": [23,[5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';